@import "open-sans";

@media (min-width: 1000px) {
    html, body {
        min-width: 1200px;
    }
}

body {
    font-family: 'Open Sans', sans-serif;
    color:#444;
    font-size: 14px;
    padding-top: 120px;
}

.customTable {
    position: relative;
}

#mainBar {
    position: fixed;
    z-index: 1;
    width: 100%;
    top: 0;
}

.topbar{
    background-color:#F5F5F5;
    line-height:15px;
    height: 30px;
}
.topbar-content>a{
    font-size:12px;
    color:#444;
    font-weight:bold;
}
.topbar-content-seperator{
    border-right:1px solid #aaa;
    padding-right:10px;
}
.topbar-img{
    padding: 0 4px 0 0;
}
.nav>.topbar-content>a{
    padding:7px 0 7px 10px;
}

#mainMenu{
    height:100%
}

#create.Product-index>a,
#create.Product-list>a{
    color: #444 !important;
}

#create.Product-index a::after,
#create.Product-list a::after{
    max-width: 100%;
    background: #444444;
}

.productLink:hover {
    outline:none;
    text-decoration: none;
}

.ShapesImage {
    width: 106%;
    margin-left: -70px;
    margin-top: 47px;
}

.navibar{
    background-color:white;
    border-bottom:1px solid #dddddd;
    height:91px;
}
.navibar-content>a{
    text-transform:uppercase;
    line-height:50px;
    color:#444;
    font-size:16px;
    font-weight:bold;
}

.navibar-content i{
    font-size: 18px;
}

.nav>.navibar-content>a, .nav>.navibar-content>span{
    font-weight: 400;
    font-size: 14px;
    color: #b0b0b0;
    display: inline;
    margin: 0 15px;
    line-height: 90px;
    padding: 10px 0;
}

.nav>.navibar-content>a:hover::after{
    max-width: 100%;
}

.nav>.navibar-content>a::after{
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    max-width: 0;
    height: 2px;
    margin-top: 12px;
    background: #99cc01;
    -webkit-transition: max-width 0.4s;
    transition: max-width 0.4s;
}

.navbar-logo{
    max-width: 180px;
    padding: 20px 20px 0 0;
    float:left;
}
.nav-pills>.navibar-content>a:hover{
    background-color: rgba(0, 0, 0, 0);
    color: #99cc01;
    outline: none;
}
.nav-pills>.navibar-content>a:active{
    background-color:transparent;
}
.nav-pills>.navibar-content>a:visited{
    background-color:transparent;
}
.seperator{
    border: 0;
    padding:0;
    margin:33px 0 0 0;
    height: 1px;
    background: #cccccc;
}
.startpage-slideshow{
    text-align:center;
    margin:auto;
    width:100%;
}
.slideshow-dots{
    text-align: center;
    margin: auto;
    height: 12px;
    position: absolute;
    bottom: -16px;
    left: 46%;
}

.slideshow-dots-inactive{
    float:left;
    margin-right: 1px;
    width: 12px;
    height: 12px;
    background: url(../Images/slideshow_nubsie_unactive.png) no-repeat;
}

.slideshow-dots-active{
    float:left;
    margin-right: 1px;
    width: 12px;
    height: 12px;
    background: url(../Images/slideshow_nubsie_active.png) no-repeat;
}

.content{
    padding-top:35px;
}
.content-box{
    margin-top:30px; /** TODO: need to be fixed when right slideshow is placed **/
    padding-bottom: 10px;
}
.content-box>H1{
    padding:38px 0 0 15px;
    margin:0;
    text-align:center;
    font-size:38px;
}

.startPageContent {
    min-height: 400px;
}

h1.startPageHeadline{
    font-family: "Open Sans", sans-serif;
    text-align: center;
    font-size: 38px;
    color: #444;
    line-height: 44px;
    font-weight: 300;
    margin-top: 35px;
    margin-bottom: 20px;
}
h2.startPageSubHeadline{
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
}
.subheadline{
    padding:28px 0 5px 0;
    text-align:center;
    font-weight:bold;
}

hr.StartPageDivider {
    border-top: 2px solid #000;
    width: 5%;
    margin-top: 30px;
}

.startPageContent .subheadline{
    text-align: left;
    padding-left: 10px;
}

.content-box-text{
    text-align: center;
    padding: 6px 10px 0 10px;
    font-size: 14px;
    line-height: 20px
}

.startPageContent.loginContainer form>div {
    padding: 30px 0;
    width: 300px;
    border: 1px solid #ccc;
}

.startPageContent.loginContainer form div h3 {
    font-weight: lighter;
    font-size: 32px;
    margin-bottom: 20px;
}

.startPageContent .content-box-text{
    text-align: left;
}

.button-green {
    margin-top:35px;
    border-radius: 5px;
    text-align: center;

    &:not(.btn-danger) {
        background-color: #99Cd01; /** 99Cd01 **/
        border:1px solid #82ad00;
    }
}

.button-green.login {
    width: 203px;
    margin: auto;
    color: #fff;
}

.button-green input, .button-green button, .button-green .fileupload-buttontext {
    padding: 10px;
    display: block;
    color: white;
}

.button-green.grey {
    background-color: #B2B0B2; /** 99Cd01 **/
    border:1px solid #B2B0B2;
}

.button-green.grey:hover {
    background-color: #99Cd01; /** 99Cd01 **/
    border:1px solid #82ad00;
}

.button-green.grey img{
    margin: 0 3px -2px -5px;
    height: 15px;
}

.button-green.disabled{
    background: #ddd;
    border-color: #ddd;
}

.button-green.btn-success{
    background-image: -webkit-gradient(linear,left top,right bottom,from(#99Cd01),to(#99Cd01));
}

.button-green.btn-success:hover{
    background-color: #99Cd01;
}
.button-green>a{
    padding:12px 16px 12px 16px;
    line-height: 14px;
    vertical-align:middle;
    font-size: 14px;
    color: white;
    display:block;
}
.button-green>a:link {  color: #FFFFFF;  text-decoration:none;  }
.button-green>a:visited {  color: #FFFFFF;  text-decoration:none;  }
.button-green>a:hover {  color: #FFFFFF;  text-decoration:none;  }
.button-green>a:active {  color: #FFFFFF;  text-decoration:none;  }

.standard-link>a:link {  color: #9E9E9E;  text-decoration:none;  }
.standard-link>a:visited {  color: #9E9E9E;  text-decoration:none;  }
.standard-link>a:hover {  color: #99cc01;  text-decoration:none;  }
.standard-link>a:active {  color: #9E9E9E;  text-decoration:none;  }

.col-md-3>h3,
.col-md-6>h3{
    font-weight:700;
    font-size:19px;
    line-height:24px;
    color:#9e9e9e;
}

.list-group{
    border-radius:0;
    margin-top:9px;
}
.list-group-item {
    line-height:10px;
    transition:color 0.2s ease-in-out;
    border-left:none;
    border-right:none;
    background-color: #2a2a2a;
}
.col-md-3>.list-group>.list-group-item>a,
.col-md-6>.list-group>.list-group-item>a{
    font-size:14px;
    font-weight:normal;
}
.list-group-item:last-child{
    border-radius:0;
    border-bottom:none;
}
.list-group-item:first-child{
    border-top:none;
    border-radius:0;
    border-bottom:1px solid #dddddd;
}
.col-md-3>.list-group>.list-group-item,
.col-md-6>.list-group>.list-group-item{
    padding-left:0;
}
.col-md-3>.dropup,
.col-md-6>.dropup{
    padding:3px 0 6px 2px;
}
.col-md-3>.dropup>.btn-default,
.col-md-6>.dropup>.btn-default{
    padding:11px;
}
.footer{
    margin-top:65px;
    padding-top:5px;
    color:#9E9E9E;
    background-color:#2A2A2A;
}
.footer-row{
    padding-top:30px;
}
.copyright{
    padding:75px 0 15px 0;
    color: #666666;
}
.footy{
    height:176px;
    color:#686350;
    text-align:center;
    background-color:#252525;
}
.back-to-top{
    color:#9f9e9a;
    font-weight:bold;
}


.shapes-content-box{
    margin:30px 0 -5px 0;
    background-color:#FAFAFA;
    border:1px solid #cccccc;
}

.shapes-content-box .text-box {
    max-width: 530px;
    font-size: 15px;
    padding-left: 54px;
}

.icon-box{
    float:left;
    height:53px;
    width:53px;
    background-color:#CCCCCC;
    vertical-align:middle;
    text-align:center;
    color:#444;
    font-size:17px;
    font-weight:bold;
    line-height: 53px;
}
.shapes-preview-element:hover .icon-box:not(.icon-box-noHover) {
    background-color:#99cc01;
    text-align:center;
    color:#fff;
}
.icon-box-active{
    float:left;
    height:53px;
    width:53px;
    background-color:#99cc01;
    text-align:center;
    color:#fff;
    font-size:17px;
    font-weight:bold;
    line-height: 53px;
    vertical-align: middle;
}
.icon-box-warning{
    background-color:red;
    border-color:red;
    padding:12px 17px 17px 11px;
}
.icon-shapes{
    margin-left:-2px;
}
.grey-text-box{
    height:53px;
    line-height:53px;
    font-size:18px;
    background-color:#F1F1F1;
    color:#444;
    border:1px solid #cccccc;
    border-bottom:none;
    border-left:none;
    padding-left:70px;
    text-decoration:none;
}

.shapes-preview-element {
    padding-bottom: 10px;
}

.shapes-preview-element:hover .grey-text-box{
    height:53px;
    line-height:53px;
    font-size:18px;
    background-color:#817F81;
    color:#fff;
    border:1px solid #817F81;
    border-bottom:none;
    border-left:none;
    padding-left:70px;
    text-decoration:none;
}
.grey-text-box-active{
    height:53px;
    max-width:358px;
    line-height:53px;
    font-size:18px;
    background-color:#817F81;
    color:#fff;
    border:1px solid #817F81;
    border-bottom:none;
    border-left:none;
    padding-left:70px;
}

.grey-text-box-active.full-width {
    width: 100%;
    max-width: none;
}

.text-box h3{
    line-height: 18px;
    font-size: 18px;
    padding: 22px 0 5px 0;
}

.productImage img{
    margin-top: 35px;
    border: 1px solid #ccc;
    max-width: 400px;
}

.productImage{
    padding-right: 72px;
}

.pop-up-headline{
    padding-right:50px;
    line-height: 1.5em;
}
.text-box p{
    padding: 8px 27px 12px 0;
    line-height: 20px;
}
.content-box-position-pages{
    padding-right: 660px;
    padding-top: 14px;
    padding-bottom: 25px;
}
.image-box{
    border:1px solid #cccccc;
    border-top:none;
}
.image-box.startpage{
    background: no-repeat center;
    background-size: contain;
    height: 244px;
}
.shapes-preview-image{
    width:100%;
    height:auto;
}
.shapes-preview-element:hover .image-box{
    background-color:#eeeeee;
}
.shapes-preview-box{
    padding:35px 0 5px 0;
}
.no-border{
    border:none;
}
.logoloop-preview-video{
    padding:22px 20px 23px 1px;
    width:100%;
}

.previewButtonHeadline {
    display: inline-block;
}

.examples-slideshow{
    /*height:296px;*/
    background-color:white;
    padding-bottom: 20px;
    overflow: hidden;
}
.examples-slideshow-images{
    padding:9px 26px 0 74px;
    width:24%
}
.example-download-text-box{
    height: 400px;
    position: relative;
}

.example-download-text-box .download-button {
    position: absolute;
    bottom: 25px;
    left: 35%;
    width: 30%;
    text-align: center;
}

.product-details-text-box {
    min-height: 400px;
}

.product-details-text-box span.bold {
    margin-top: 35px;
    display: inline-block;
}

.product-details-text-box .text-box {
    padding-right: 0;
}

.text-box{
    padding:0 15px 0 25px;
}
.text-box-wider{
    padding-left:18px;
}
.detail-list{
    padding:10px 0 0 0;
    color:#444444;
}
.detail-list ul{
    padding: 3px 0 0 0;
    line-height: 20px;
    font-size: 13px;
}
.download-list-box{
    padding:35px 0 0 15px;
}
.download-list-box>ul{
    padding: 10px 0 8px 40px;
    font-size: 18px;
}
.download-list-box>ul>li{
    padding: 2px 0 4px 0px;
    font-size: 14px;
    line-height: 20px;
}
.download-list-box>ul>li>img{
    padding-right:12px;
}
.download-list-headline{
    padding: 0 0 0 40px;
    font-weight:bold;
    line-height: 21px;
}
.download-list-subheadline{
    padding-bottom:3px;
}

span.bold{
    font-weight:bold;
    font-size:15px;
}

.breadcrumb-box{
    width: 100%;
    overflow:hidden;
    margin:30px 0;
    position: relative;
}

.breadcrumb-box:before {
    content: "";
    display: block;
    padding-top: 3.9%;
}

.breadcrumb-box img{
    position: absolute;
    width: 100%;
    height: auto;
}

.text-box>.progress>.progress-bar {
    background-image: -webkit-gradient(linear,left top,right bottom,from(#99Cd01),to(#99Cd01));
}
.text-box>.progress>.progress-bar-warning{
    background-color:#d8d8d8;
}
.progress-bar-description{
    font-style: italic;
    line-height:32px;
    font-size:14px;
    padding:16px 0 0 11px;
}
.text-box>.progress{
    height:47px;
    width:325px;
}
.grey-info-box{
    height:100%;
    border:1px solid #cccccc;
    background-color:#CCCCCC;
}
.grey-info-box-content{
    font-style:italic;
    font-size:14px;
    color:#444;
    padding-top:10px;
    line-height:18px;
}
.grey-info-box-content ul{
    padding:3px 5px 5px 0;
}
.grey-info-box-content ul>li{
    padding:1px 0 1px 0;
}
.left-box{
    height:100%;
}
.standard-content-box{
    min-height:137px;
    border:1px solid #cccccc;
    background-color:white;
}
.height-adjust-first, .height-adjust-second {
    padding-bottom: 300px;
    margin-bottom: -300px;
}
.pdf-upload-row {
    overflow: hidden;
}
.data-check-list-box {
    border:1px solid #cccccc;
    background-color:white;

}
.data-check-first-row {
    font-size:18px;
    background-color:white;
    border:1px solid #cccccc;
    margin:45px 0 16px 0;
    padding:20px 25px;

}
.data-check-row-left {
    padding-left:20px
}
.data-check-row-righ t{
    padding-left:33px
}
.data-check-list {
    background-color:white;
    padding: 15px;
}
.data-check-list-error {
    background-color: #FFCCCB;
}
.data-check-list-attention{
    background-color: #FFA3A4;
    font-size:18px;
}
.data-check-list-description{
    margin:15px 0 15px 85px;
    color:#444;
    font-size:18px;
}
.data-check-list-description-attention{
    margin:3px 0 5px 70px;
    font-size:14px;
    line-height:21px;
    padding:8px 30px 0 0;
}
.data-check-image-box{
    float:left;
    margin-left:20px;
}
.data-check-list-output{
    color:#77a200;
    font-style:italic;
    margin:5px 0 0 0;
    font-size:14px;
    padding-left: 35px;

}
.data-check-list-output-error{
    color:red;
}
.data-check-list-attention img{
    margin:3px 0 0 0;
}
.data-check-list-seperator{
    border: 1px solid #cccccc;
    margin:auto;
}

.logo-position-row{
    padding-top:17px;
}
.logoPositioning, .previewPdf{
    margin: 64px auto;
}

.previewPdf img{
    width: 100%;
    height: 100%;
}

.logo-position-list-row{
    border-top:solid 1px #cecece;
    padding:3px 0 ;
}
.logo-position-list-row:hover, .logo-position-list-row-active{
    background-color:#E0F0B2;
}
.logo-check-image-box{
    margin-left:15px;
}
.logo-position-description{
    font-size:15px;
    margin-left:77px;
}
.logo-position-image-box{
    margin:7px 0 0 15px;
    float:left;

}
.logo-check-logo-image{
    margin:11px 28px 0 15px
}
.logo-check-logo-box{
    margin:15px 0 0 0;
}
.logo-check-placement-box-headline{
    padding-top:10px;
}
.text-box-half{
    width:50%;
}
.preview-shape-image-box{
    border-left:solid 1px #cecece;
    padding:11.1% 33px;
}
.preview-shape-image-box-active {
    background-color:#E0F0B2;
}
.number-box-preview{
    margin: 0 0 5px 26px;

}
.pop-up-box{
    padding:15px;
    border:1px solid #cecece;
    margin: 23px 55px 0 0;

}
.data-check-pop-up{
    padding:7px 0 0 50px;
}

.modal-dialog .data-check-pop-up{
    padding: 7px 0 20px 50px;
}

.modal-dialog .grey-text-box-active{
    margin-bottom: 20px;
}

.data-check-pop-up-warning{
    padding-left:55px;
}
.pop-up-box>.form-group>input {
    border-radius:0;
    height:23px;
}
.pop-up-box>.form-group>textarea {
    border-radius:0;
    min-height:110px;
}
.pop-up-box>.form-group>label{
    font-size:14px;
    line-height:30px;
}
.modal-form>.modal-dialog>.modal-content{
    border-radius:0;
    border:1px;
}
.modal-form>.modal-dialog{
    width:700px;
}
.modal-dialog{
    background-color:white;
}
#example-designs>.modal-dialog{
    width:1130px;
}
.popover-title{
    color:black;
}
.popover-content{
    color:black;
}
.nav > li > a:focus{
    background-color:transparent;
}



/** datapicker start **/
.input-with-datepicker>.input-group>.input-group-addon{
    padding:0;
    margin:0 0 0 20px;
    border:0;
    background-color:transparent;
}
.input-with-datepicker>.input-group>.input-group-addon>img{
    margin-left:20px;
}
.input-group-nodatepicker>input.form-control{
    width:80%;
}
.input-with-datepicker>.input-group>.form-control{
    border-radius:0;
}
.datepicker>.datepicker-days>.table-condensed>tbody>tr>td{
    padding:1px 6px;
    text-align:center;
    border-radius:3px;
}
.datepicker>.datepicker-days>.table-condensed>tbody>tr>td:hover {
    background-color:#cecece;
}
.datepicker>.datepicker-days>.table-condensed>tbody>tr>.disabled:hover {
    background-color:red;
}
/** datapicker end **/

.step-1{top: 0;}
.step-2{top: -100%;}
.step-3{top: -201%;}
.step-4{top: -302%;}
.step-5{top: -403%;}


.cycle-slideshow{
    overflow: hidden;
}

.slideshow.cycle-slideshow{
    overflow: visible;
}

.slideshow-item.small{
    width: 280px;
    height: 147px;
}

.slideshow-item.small img{
    width: 100%;
}

.slideshow-item.medium{
    width: 370px;
    height: 147px;
    text-align: center;
}

.slideshow-item.medium img{
    width: 78%;
}

.slideshow-item.large{
    width: 560px;
    height: 147px;
    text-align: center;
}

.slideshow-item.large img{
    width: 63%;
}

.ui-datepicker{
    z-index: 1050 !important;
}

.button-green input,
.button-green button{
    color: white;
    background: none;
    border: none;
}

.input-group input{
    width: 100%;
}

#loginform .input-group input{
    width: auto;
}

.customerForm{
    margin-top: 15px;
}

.customerForm .input-group{
    padding-right: 15px;
}

.customerForm .button-green{
    margin-left: 15px;
}

sup{
    font-size: 75%;
}

div.bold{
    font-weight: bold;
}


::-webkit-input-placeholder {
    line-height: 22px;
}

:-moz-placeholder { /* Firefox 18- */
    line-height: 22px;
}

::-moz-placeholder {  /* Firefox 19+ */
    line-height: 22px;
}

:-ms-input-placeholder {
    line-height: 22px;
}

.gray-background{
    background: #ececec;
}

.higherLineHeight{
    line-height: 1.5em;
}

.white{
    color: #FFF;
}

.previewVideo {
    margin: 25px;
}

table.detailsTable {
    border-spacing: 15px 5px;
}

.spinner > div:first-of-type {
    margin-bottom: 20px;
}

.spinner h1 {
    line-height: 2.5;
}

.shapes-preview-element dt {
    clear: left;
    float: left;
    padding: 4px 0;
    width: 100px;
}

.shapes-preview-element dd {
    clear: right;
    padding: 4px 0 4px 40px;
}

#controls {
    padding:0 0 0 29px;
}

#controls .button-green {
    float: left;
    margin-right: 15px;
}

.f3-form-error {
    border: solid 1px #A94442;
    background-color: #FFCCCB;
}

/* Off Canvas */
#offCanvasBar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.25s;
    background: #333;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.6);
    display: none;
}

.entry {
    display: inline-block;
    cursor: pointer;
    width: 100%;
    text-align: center;
    color: white;
    font-weight: 100;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid white;
    transition: color 0.2s ease-in-out;
}

.entry:hover {
    text-decoration: none;
    color: #99CC01;
}

.entry.entry-active {
    color: #99CC01;
}

#offCanvasIcon span img{
    width: 13px;
    margin-right: 2px;
    cursor: pointer;
}

.container {
    transition: margin-right .5s;
}

.overall {
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.7;
    display: none;
    background: black;
}



.container {
    box-sizing: border-box;
    padding: 0 40px;
}

#mainMenu .nav-pills>li+li {
    margin-left: 1px;
}
#mainMenu.nav>.navibar-content>a,
#mainMenu.nav>.navibar-content>span {
    margin: 0 10px;
}
#offCanvasIcon {
    display: none;
    margin-top: 30px;
    font-size: 2rem;
}
#offCanvasBar .create {
    background: #f7f7f7;
    color: #444;
}

#languageMenu a {
    text-transform: uppercase;
    color: #444;
}
#languageMenu a.dropdown-toggle {
    padding: 3px 0 3px 10px;
    box-shadow: 0 0 0;
    border: 0;
}
#languageMenu .dropdown-menu {
    border-radius: 0;
    border: none;
    box-shadow: 0px 1px 4px 0px #dddddd;
    margin: 0;
}
#languageMenu .dropdown-menu li:hover a {
    color: rgba(68, 68, 68, 0.5);
    background: none;
}
#languageMenu .dropdown-menu li a {
    padding: 7px 10px 7px 23px;
}
#languageMenu img {
    vertical-align: middle;
}

@media (min-width: 1000px) {
    .container {
        width: 1200px;
    }
}

@media (max-width: 1000px) {

    .modal-form>.modal-dialog {
        width: auto;
    }

    .logoPositioning {
        width: 100% !important;
        height: auto !important;
    }

    .pull-left.button-green.fileinput-button.btn-success {
        max-width: calc(100% - 27px);
    }

    .text-box>.progress {
        width: calc(100% - 27px);
        margin-right: 27px;
    }

    .shapes-preview-element .productImage {
        padding-right: 15px;
    }

    .text-box {
        padding-left: 54px !important;
        margin-top: 10px !important;
    }

    .ShapesImage {
        width: calc(100% - 100px);
        margin: 10px 50px 0 50px;
    }

    #offCanvasIcon {
        height: 100%;
        display: block;
    }

    #mainMenu {
        display: none !important;
    }

    #controls {
        padding: 0;
    }

    #controls p {
        margin-left: 20px;
    }

    #controls .panels {
        width: 100%;
    }

    #controls .button-green {
        float: none;
        width: 200px;
        text-align: center;
        margin: 0 auto 10px auto;
    }

    .preview-shape-image-box {
        width: 25%;
    }

    .goToPanel {
        border-bottom: 1px solid #D1D1D1;
        padding: 20px 0;
        text-align: center;
    }

    .number-box-preview {
        margin: 0 0 5px 0;
    }
}

@media (max-width: 520px) {

    .grey-background .button-green, .grey-background .button-grey {
        width: 100%;
        margin-top: 5px;
    }
    .preview-shape-image-box {
        width: 50%;
    }
}

.dataTable .search {
    width: 100%;
}

div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
}

.dataTable td,
.dataTable th{
    border: 1px solid #ddd !important;
}

.clickable {
    cursor: pointer;
}

form.selectColumns,
form.selectColumns select {
    width: 100%;
    min-height: 200px;
    margin-bottom: 5px;
}

.btn-default.savebutton span,
.btn-default.closedialog span {
    float: left
}
