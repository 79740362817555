.hidden{
    display:none;
}
.unclickable{
    pointer-events:none;
}
#videoBox img {
    width: 100%;
}

#waitingSpinner div{
    position: relative;
    margin: 10px auto;
    padding: 5px;
    border-radius: 10px;
    width: 100px;
    height: 100px;
    z-index: 10;
}

#waitingSpinner div img{
    width: 100%;
}

#loadingSpinner div img {
    width: 90px;
    z-index: 10;
}

#videoBox {
    perspective: 10000px;
    width: 100%;
    height: 100%;
    position: relative;
}

#panelA1, #panelB1, #panelB2, #panelC1, #panelC2, #panelD1, #panelD2, #panelA2 {
    position: absolute;
    width: 100%;
    height: 100%;
}

#panelA1 {
    z-index: 8;
}

#panelB1 {
    z-index: 7;
}

#panelB2 {
    z-index: 6;
}

#panelC1 {
    z-index: 5;
}

#panelC2 {
    z-index: 4;
}

#panelD1 {
    z-index: 3;
}

#panelD2 {
    z-index: 2;
}

#panelA2 {
    z-index: 0;
}

#panelA1 .leftHalf {
    width: 50%;
    float: left;
    height: 100%;
}

#panelA1 .rightHalf {
    width: 50%;
    float: right;
    height: 100%;
}

#panelB1 .leftHalf {
    width: 50%;
    float: left;
    height: 100%;
}

#panelB1 .leftHalf img {
    width: 50%;
    margin-right: 50%;
    float: left;
}

#panelB1 .middle {
    position: absolute;
    margin: 0 25%;
    width: 50%;
    height: 100%;
}

#panelB1 .middle img {
    float: left;
}

#panelB1 .rightHalf {
    width: 50%;
    float: right;
    height: 100%;
}

#panelB1 .rightHalf img {
    width: 50%;
    margin-left: 50%;
    float: left;
}

#panelB2{
    height: 100%;
}

#panelB2 .topHalf {
    clear: both;
    height: 50%;
}

#panelB2 .topHalf img {
    width: 25%;
    float: left;
}

#panelB2 .topHalf img {
    width: 25%;
}

#panelB2 .topHalf img.middleImage {
    width: 50%;
}

#panelB2 .middle {
    clear: both;
}

#panelB2 .middle {
    position: absolute;
    height: 50%;
    top: 25%;
}

#panelB2 .middle img {
    width: 25%;
    float: left;
}

#panelB2 .middle .leftSpace {
    padding-left: 1px;
    width: 25%;
}

#panelB2 .middle .rightSpace {
    padding-right: 1px;
    width: 25%;
}

#panelB2 .bottomHalf {
    clear: both;
    height: 50%;
}

#panelB2 .bottomHalf div {
    width: 25%;
    float: left;
}

#panelB2 .bottomHalf img {
    float: left;
}

#panelB2 .bottomHalf img.middleImage {
    width: 50%;
}

#panelC1 .middle {
    position: absolute;
    margin: 0 25%;
    width: 50%;
    height: 100%;
}

#panelC1 .middle .middleTop{
    height: 50%;
}

#panelC1 .middle .middleBottom{
    height: 50%;
}

#panelC1 .middle .rightSpace,
#panelC1 .middle .leftSpace{
    height: 50%;
}



#panelC1 .middle div img {
    width: 50%;
    margin-right: -4px;
    margin-bottom: -4px;
}

#panelC1 .middle .rightSpace{
    width: 50%;
    margin-left: -1px;
}
#panelC1 .middle .leftSpace{
    width: 50%;
    margin-left: 1px;
}
#panelC2 .leftHalf {
    height: 100%;
    width: 50%;
    float: left;
    margin-left: -2px;
}

#panelC2 .leftHalf img {
    width: 50%;
    float: left;
}

#panelC2 .rightHalf {
    height: 100%;
    width: 50%;
    float: right;
    margin-left: 2px;
}

#panelC2 .rightHalf img {
    width: 50%;
    float: left;
}

#panelD1 .leftHalf {
    width: 50%;
    height: 100%;
    float: left;
}

#panelD1 .leftHalf img {
    width: 50%;
    margin-right: 50%;
    float: left;
}

#panelD1 .middle {
    position: absolute;
    margin: 0 25%;
    width: 50%;
    height: 100%;
    z-index: 4;
}

#panelD1 .middle img {
    float: left;
}

#panelD1 .rightHalf {
    width: 50%;
    height: 100%;
    float: right;
}

#panelD1 .rightHalf img {
    width: 50%;
    margin-left: 50%;
    float: right;
}

#panelD2 img {
    float: left;
}

#panelA2 .topHalf {
    clear: both;
    width: 100%;
    height: 50%;
    position: absolute;
}

#panelA2 .topHalf img {
    width: 50%;
    float: left;
}

#panelA2 .middle {
    clear: both;
    position: absolute;
    z-index: 10;
    height: 50%;
    top: 25%;
    width: 100%
}

#panelA2 .middle img {
    width: 50%;
    float: left;
}

#panelA2 .bottomHalf {
    clear: both;
    z-index: 8;
    width: 100%;
    height: 50%;
    position: absolute;
    bottom:0;
}

#panelA2 .bottomHalf img {
    width: 50%;
    margin-top: 25%;
    float: left;
}

#panelD1 .top {
    padding-bottom: 1px;
}

#panelD1 .bottom {
    padding-top: 1px;
}

#panelD2 .topHalf {
    padding-bottom: 2px;
    height: 50%;
}

#panelD2 .bottomHalf {
    padding-top: 2px;
    height: 50%;
}

.spinLoader {
    -webkit-animation: spinLoader 1s steps(8, end) infinite;
    -moz-animation: spinLoader 1s steps(8, end) infinite;
    -o-animation: spinLoader 1s steps(8, end) infinite;
    animation: spinLoader 1s steps(8, end) infinite;
}
.animated {
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -o-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

.animated.faster{
    -webkit-animation-duration:2000ms;
    -moz-animation-duration:2000ms;
    -o-animation-duration:2000ms;
    animation-duration:2000ms;
}
.flipA1 {
    /** name,duration,timing-function,delay,iteration-count,direcon **/
    -webkit-animation:  flipA1 2000ms linear 0s 1 normal;
    -moz-animation:     flipA1 2000ms linear 0s 1 normal;
    -o-animation:       flipA1 2000ms linear 0s 1 normal;
    animation:          flipA1 2000ms linear 0s 1 normal;
}
.flipA2 {
    -webkit-animation:  flipA2 2000ms linear 0s 1 normal;
    -moz-animation:     flipA2 2000ms linear 0s 1 normal;
    -o-animation:       flipA2 2000ms linear 0s 1 normal;
    animation:          flipA2 2000ms linear 0s 1 normal;
}
.flipB4_B5L_B6 {
    -webkit-animation:  flipB4_B5L_B6 2000ms linear 0s 1 normal;
    -moz-animation:     flipB4_B5L_B6 2000ms linear 0s 1 normal;
    -o-animation:       flipB4_B5L_B6 2000ms linear 0s 1 normal;
    animation:          flipB4_B5L_B6 2000ms linear 0s 1 normal;
}
.flipB1_B2R_B3 {
    -webkit-animation:  flipB1_B2R_B3 2000ms linear 0s 1 normal;
    -moz-animation:     flipB1_B2R_B3 2000ms linear 0s 1 normal;
    -o-animation:       flipB1_B2R_B3 2000ms linear 0s 1 normal;
    animation:          flipB1_B2R_B3 2000ms linear 0s 1 normal;
}
.removePanelB1 {
    -webkit-animation:  removePanelB1 2000ms linear 0s 1 normal;
    -moz-animation:     removePanelB1 2000ms linear 0s 1 normal;
    -o-animation:       removePanelB1 2000ms linear 0s 1 normal;
    animation:          removePanelB1 2000ms linear 0s 1 normal;
}
.flipB4_C3_B1 {
    -webkit-animation:  flipB4_C3_B1 2000ms linear 0s 1 normal;
    -moz-animation:     flipB4_C3_B1 2000ms linear 0s 1 normal;
    -o-animation:       flipB4_C3_B1 2000ms linear 0s 1 normal;
    animation:          flipB4_C3_B1 2000ms linear 0s 1 normal;
}
.flipB6_C4_B3 {
    -webkit-animation:  flipB6_C4_B3 2000ms linear 0s 1 normal;
    -moz-animation:     flipB6_C4_B3 2000ms linear 0s 1 normal;
    -o-animation:       flipB6_C4_B3 2000ms linear 0s 1 normal;
    animation:          flipB6_C4_B3 2000ms linear 0s 1 normal;
}
.flipC1_C5 {
    -webkit-animation:  flipC1_C5 2000ms linear 0s 1 normal;
    -moz-animation:     flipC1_C5 2000ms linear 0s 1 normal;
    -o-animation:       flipC1_C5 2000ms linear 0s 1 normal;
    animation:          flipC1_C5 2000ms linear 0s 1 normal;
}
.flipC2_C6 {
    -webkit-animation: flipC2_C6 2000ms linear 0s 1 normal;
    -moz-animation:    flipC2_C6 2000ms linear 0s 1 normal;
    -o-animation:      flipC2_C6 2000ms linear 0s 1 normal;
    animation:         flipC2_C6 2000ms linear 0s 1 normal;
}
.removePanelC1 {
    -webkit-animation: removePanelC1 2000ms linear 0s 1 normal;
    -moz-animation:    removePanelC1 2000ms linear 0s 1 normal;
    -o-animation:      removePanelC1 2000ms linear 0s 1 normal;
    animation:         removePanelC1 2000ms linear 0s 1 normal;
}
.flipC1_B5_C2 {
    -webkit-animation: flipC1_B5_C2 2000ms linear 0s 1 normal;
    -moz-animation:    flipC1_B5_C2 2000ms linear 0s 1 normal;
    -o-animation:      flipC1_B5_C2 2000ms linear 0s 1 normal;
    animation:         flipC1_B5_C2 2000ms linear 0s 1 normal;
}
.flipC5_B2_C6 {
    -webkit-animation: flipC5_B2_C6 2000ms linear 0s 1 normal;
    -moz-animation:    flipC5_B2_C6 2000ms linear 0s 1 normal;
    -o-animation:      flipC5_B2_C6 2000ms linear 0s 1 normal;
    animation:         flipC5_B2_C6 2000ms linear 0s 1 normal;
}
.flipD1L_D2L {
    -webkit-animation: flipD1L_D2L 2000ms linear 0s 1 normal;
    -moz-animation:    flipD1L_D2L 2000ms linear 0s 1 normal;
    -o-animation:      flipD1L_D2L 2000ms linear 0s 1 normal;
    animation:         flipD1L_D2L 2000ms linear 0s 1 normal;
}
.flipD1R_D2R {
    -webkit-animation: flipD1R_D2R 2000ms linear 0s 1 normal;
    -moz-animation:    flipD1R_D2R 2000ms linear 0s 1 normal;
    -o-animation:      flipD1R_D2R 2000ms linear 0s 1 normal;
    animation:         flipD1R_D2R 2000ms linear 0s 1 normal;
}
.flipD1 {
    -webkit-animation: flipD1 2000ms linear 0s 1 normal;
    -moz-animation:    flipD1 2000ms linear 0s 1 normal;
    -o-animation:      flipD1 2000ms linear 0s 1 normal;
    animation:         flipD1 2000ms linear 0s 1 normal;
}
.flipD2 {
    -webkit-animation: flipD2 2000ms linear 0s 1 normal;
    -moz-animation:    flipD2 2000ms linear 0s 1 normal;
    -o-animation:      flipD2 2000ms linear 0s 1 normal;
    animation:         flipD2 2000ms linear 0s 1 normal;
}
.flipA1T_A2T {
    -webkit-animation: flipA1T_A2T 2000ms linear 0s 1 normal;
    -moz-animation:    flipA1T_A2T 2000ms linear 0s 1 normal;
    -o-animation:      flipA1T_A2T 2000ms linear 0s 1 normal;
    animation:         flipA1T_A2T 2000ms linear 0s 1 normal;
}
.flipA1B_A2B {
    -webkit-animation: flipA1B_A2B 2000ms linear 0s 1 normal;
    -moz-animation:    flipA1B_A2B 2000ms linear 0s 1 normal;
    -o-animation:      flipA1B_A2B 2000ms linear 0s 1 normal;
    animation:         flipA1B_A2B 2000ms linear 0s 1 normal;
}
/** Flip A1 **/
@-webkit-keyframes flipA1 {         0%  {  -webkit-transform: perspective(2000px) rotateY(0deg);    }
                                    100%{  -webkit-transform: perspective(2000px) rotateY(-90deg);  }
}
@-moz-keyframes flipA1 {            0%  {  -moz-transform: perspective(2000px) rotateY(0deg);    }
                                    100%{  -moz-transform: perspective(2000px) rotateY(-90deg);  }
}
@-o-keyframes flipA1 {              0%  {  -o-transform: perspective(2000px) rotateY(0deg);    }
                                    100%{  -o-transform: perspective(2000px) rotateY(-90deg);  }
}
@keyframes flipA1 {                 0%  {  transform: perspective(2000px) rotateY(0deg);    }
                                    100%{  transform: perspective(2000px) rotateY(-90deg);  }
}

/** Flip A2 **/
@-webkit-keyframes flipA2 {         0%  {  -webkit-transform: perspective(2000px) rotateY(0deg);    }
                                    100%{  -webkit-transform: perspective(2000px) rotateY(90deg);   }
}
@-moz-keyframes flipA2 {            0%  {  -moz-transform: perspective(2000px) rotateY(0deg);    }
                                    100%{  -moz-transform: perspective(2000px) rotateY(90deg);   }
}
@-o-keyframes flipA2 {              0%  {  -o-transform: perspective(2000px) rotateY(0deg);   }
                                    100%{  -o-transform: perspective(2000px) rotateY(90deg);  }
}
@keyframes flipA2 {                 0%  {  transform: perspective(2000px) rotateY(0deg);    }
                                    100%{  transform: perspective(2000px) rotateY(90deg);   }
}

/** Flip B4_B5L_B6 **/
@-webkit-keyframes flipB4_B5L_B6 {  0%  {  -webkit-transform: perspective(2000px) rotateY(90deg);  }
                                    100%{  -webkit-transform: perspective(2000px) rotateY(0deg);  }
}
@-moz-keyframes flipB4_B5L_B6 {     0%  {  -moz-transform: perspective(2000px) rotateY(90deg);  }
                                    100%{  -moz-transform: perspective(2000px) rotateY(0deg);  }
}
@-o-keyframes flipB4_B5L_B6 {       0%  {  -o-transform: perspective(2000px) rotateY(90deg);  }
                                    100%{  -o-transform: perspective(2000px) rotateY(0deg);  }
}
@keyframes flipB4_B5L_B6 {          0%  {  transform: perspective(2000px) rotateY(90deg);  }
                                    100%{  transform: perspective(2000px) rotateY(0deg);  }
}

/** Flip B1_B2R_B3 **/
@-webkit-keyframes flipB1_B2R_B3 {  0%  {  -webkit-transform: perspective(2000px) rotateY(-90deg);  }
                                    100%{  -webkit-transform: perspective(2000px) rotateY(0deg);  }
}
@-moz-keyframes flipB1_B2R_B3 {     0%  {  -moz-transform: perspective(2000px) rotateY(-90deg);  }
                                    100%{  -moz-transform: perspective(2000px) rotateY(0deg);  }
}
@-o-keyframes flipB1_B2R_B3 {       0%  {  -o-transform: perspective(2000px) rotateY(-90deg);  }
                                    100%{  -o-transform: perspective(2000px) rotateY(0deg);  }
 }
@keyframes flipB1_B2R_B3 {          0%  {  transform: perspective(2000px) rotateY(-90deg);  }
                                    100%{  transform: perspective(2000px) rotateY(0deg);    }
}

/** Flip B4_C3_B1 **/
@-webkit-keyframes flipB4_C3_B1 {   0%  {  -webkit-transform: perspective(2000px) rotateX(0deg);    }
                                    100%{  -webkit-transform: perspective(2000px) rotateX(90deg);   }
}
@-moz-keyframes flipB4_C3_B1 {      0%  {  -moz-transform: perspective(2000px) rotateX(0deg);    }
                                    100%{  -moz-transform: perspective(2000px) rotateX(90deg);   }
}
@-o-keyframes flipB4_C3_B1 {        0%  {  -o-transform: perspective(2000px) rotateX(0deg);    }
                                    100%{  -o-transform: perspective(2000px) rotateX(90deg);   }
}
@keyframes flipB4_C3_B1 {           0%  {  transform: perspective(2000px) rotateX(0deg);    }
                                    100%{  transform: perspective(2000px) rotateX(90deg);   }
}

/** Flip B6_C4_B3 **/
@-webkit-keyframes flipB6_C4_B3 {   0%  {  -webkit-transform: perspective(2000px) rotateX(0deg);  }
                                    100%{  -webkit-transform: perspective(2000px) rotateX(-90deg);  }
}
@-moz-keyframes flipB6_C4_B3 {      0%  {  -moz-transform: perspective(2000px) rotateX(0deg);  }
                                    100%{  -moz-transform: perspective(2000px) rotateX(-90deg);  }
}
@-o-keyframes flipB6_C4_B3 {        0%  {  -o-transform: perspective(2000px) rotateX(0deg);  }
                                    100%{  -o-transform: perspective(2000px) rotateX(-90deg);  }
}
@keyframes flipB6_C4_B3 {           0%  {  transform: perspective(2000px) rotateX(0deg);  }
                                    100%{  transform: perspective(2000px) rotateX(-90deg);  }
}

/** Flip C1_C5 **/
@-webkit-keyframes flipC1_C5 {      0%  {  -webkit-transform: perspective(2000px) rotateX(-90deg);  }
                                    100%{  -webkit-transform: perspective(2000px) rotateX(0deg);  }
}
@-moz-keyframes flipC1_C5 {         0%  {  -moz-transform: perspective(2000px) rotateX(-90deg);  }
                                    100%{  -moz-transform: perspective(2000px) rotateX(0deg);  }
}
@-o-keyframes flipC1_C5 {           0%  {  -o-transform: perspective(2000px) rotateX(-90deg);  }
                                    100%{  -o-transform: perspective(2000px) rotateX(0deg);  }
}
@keyframes flipC1_C5 {              0%  {  transform: perspective(2000px) rotateX(-90deg);  }
                                    100%{  transform: perspective(2000px) rotateX(0deg);  }
}

/** Flip C2_C6 **/
@-webkit-keyframes flipC2_C6 {      0%  {  -webkit-transform: perspective(2000px) rotateX(90deg);  }
                                    100%{  -webkit-transform: perspective(2000px) rotateX(0deg);  }
}
@-moz-keyframes flipC2_C6 {         0%  {  -moz-transform: perspective(2000px) rotateX(90deg);  }
                                    100%{  -moz-transform: perspective(2000px) rotateX(0deg);  }
}
@-o-keyframes flipC2_C6 {           0%  {  -o-transform: perspective(2000px) rotateX(90deg);  }
                                    100%{  -o-transform: perspective(2000px) rotateX(0deg);  }
}
@keyframes flipC2_C6 {              0%  {  transform: perspective(2000px) rotateX(90deg);  }
                                    100%{  transform: perspective(2000px) rotateX(0deg);  }
}

/** Flip C1_B5_C2 **/
@-webkit-keyframes flipC1_B5_C2 {   0%  {  -webkit-transform: perspective(2000px) rotateY(0deg);  }
                                    100%{  -webkit-transform: perspective(2000px) rotateY(-90deg);  }
}
@-moz-keyframes flipC1_B5_C2 {      0%  {  -moz-transform: perspective(2000px) rotateY(0deg);  }
                                    100%{  -moz-transform: perspective(2000px) rotateY(-90deg);  }
}
@-o-keyframes flipC1_B5_C2 {        0%  {  -o-transform: perspective(2000px) rotateY(0deg);  }
                                    100%{  -o-transform: perspective(2000px) rotateY(-90deg);  }
}
@keyframes flipC1_B5_C2 {           0%  {  transform: perspective(2000px) rotateY(0deg);  }
                                    100%{  transform: perspective(2000px) rotateY(-90deg);  }
}

/** Flip C5_B2_C6 **/
@-webkit-keyframes flipC5_B2_C6 {   0%  {  -webkit-transform: perspective(2000px) rotateY(0deg);  }
                                    100%{  -webkit-transform: perspective(2000px) rotateY(90deg);  }
}
@-moz-keyframes flipC5_B2_C6 {      0%  {  -moz-transform: perspective(2000px) rotateY(0deg);  }
                                    100%{  -moz-transform: perspective(2000px) rotateY(90deg);  }
}
@-o-keyframes flipC5_B2_C6 {        0%  {  -o-transform: perspective(2000px) rotateY(0deg);  }
                                    100%{  -o-transform: perspective(2000px) rotateY(90deg);  }
}
@keyframes flipC5_B2_C6 {           0%  {  transform: perspective(2000px) rotateY(0deg);  }
                                    100%{  transform: perspective(2000px) rotateY(90deg);  }
}

/** Flip D1L_D2L **/
@-webkit-keyframes flipD1L_D2L {    0%  {  -webkit-transform: perspective(2000px) rotateY(90deg);  }
                                    100%{  -webkit-transform: perspective(2000px) rotateY(0deg);  }
}
@-moz-keyframes flipD1L_D2L {       0%  {  -moz-transform: perspective(2000px) rotateY(90deg);  }
                                    100%{  -moz-transform: perspective(2000px) rotateY(0deg);  }
}
@-o-keyframes flipD1L_D2L {         0%  {  -o-transform: perspective(2000px) rotateY(90deg);  }
                                    100%{  -o-transform: perspective(2000px) rotateY(0deg);  }
}
@keyframes flipD1L_D2L {            0%  {  transform: perspective(2000px) rotateY(90deg);  }
                                    100%{  transform: perspective(2000px) rotateY(0deg);  }
}

/** Flip D1R_D2R **/
@-webkit-keyframes flipD1R_D2R {    0%  {  -webkit-transform: perspective(2000px) rotateY(-90deg);  }
                                    100%{  -webkit-transform: perspective(2000px) rotateY(0deg);  }
}
@-moz-keyframes flipD1R_D2R {       0%  {  -moz-transform: perspective(2000px) rotateY(-90deg);  }
                                    100%{  -moz-transform: perspective(2000px) rotateY(0deg);  }
}
@-o-keyframes flipD1R_D2R {         0%  {  -o-transform: perspective(2000px) rotateY(-90deg);  }
                                    100%{  -o-transform: perspective(2000px) rotateY(0deg);  }
}
@keyframes flipD1R_D2R {            0%  {  transform: perspective(2000px) rotateY(-90deg);  }
                                    100%{  transform: perspective(2000px) rotateY(0deg);  }
}

/** Flip D1 **/
@-webkit-keyframes flipD1 {         0%  {  -webkit-transform: perspective(2000px) rotateX(0deg);  }
                                    100%{  -webkit-transform: perspective(2000px) rotateX(90deg);  }
}
@-moz-keyframes flipD1 {            0%  {  -moz-transform: perspective(2000px) rotateX(0deg);  }
                                    100%{  -moz-transform: perspective(2000px) rotateX(90deg);  }
}
@-o-keyframes flipD1 {              0%  {  -o-transform: perspective(2000px) rotateX(0deg);  }
                                    100%{  -o-transform: perspective(2000px) rotateX(90deg);  }
}
@keyframes flipD1 {                 0%  {  transform: perspective(2000px) rotateX(0deg);  }
                                    100%{  transform: perspective(2000px) rotateX(90deg);  }
}
/** Flip D2 **/
@-webkit-keyframes flipD2 {         0%  {  -webkit-transform: perspective(2000px) rotateX(0deg);  }
                                    100%{  -webkit-transform: perspective(2000px) rotateX(-90deg);  }
}
@-moz-keyframes flipD2 {            0%  {  -moz-transform: perspective(2000px) rotateX(0deg);  }
                                    100%{  -moz-transform: perspective(2000px) rotateX(-90deg);  }
}
@-o-keyframes flipD2 {              0%  {  -o-transform: perspective(2000px) rotateX(0deg);  }
                                    100%{  -o-transform: perspective(2000px) rotateX(-90deg);  }
}
@keyframes flipD2 {                 0%  {  transform: perspective(2000px) rotateX(0deg);  }
                                    100%{  transform: perspective(2000px) rotateX(-90deg);  }
}

/** Flip A1T_A2T **/
@-webkit-keyframes flipA1T_A2T {    0%  {  -webkit-transform: perspective(1000px) rotateX(-90deg);  }
                                    100%{  -webkit-transform: perspective(1000px) rotateX(0deg);  }
}
@-moz-keyframes flipA1T_A2T {       0%  {  -moz-transform: perspective(1000px) rotateX(-90deg);  }
                                    100%{  -moz-transform: perspective(1000px) rotateX(0deg);  }
}
@-o-keyframes flipA1T_A2T {         0%  {  -o-transform: perspective(1000px) rotateX(-90deg);  }
                                    100%{  -o-transform: perspective(1000px) rotateX(0deg);  }
}
@keyframes flipA1T_A2T {            0% {   transform: perspective(1000px) rotateX(-90deg);  }
                                    100%{  transform: perspective(1000px) rotateX(0deg);  }
}

/** Flip A1B_A2B **/
@-webkit-keyframes flipA1B_A2B {    0%  {  -webkit-transform: perspective(2000px) rotateX(90deg);  }
                                    100%{  -webkit-transform: perspective(2000px) rotateX(0deg);  }
}
@-moz-keyframes flipA1B_A2B {       0%  {  -moz-transform: perspective(2000px) rotateX(90deg);  }
                                    100%{  -moz-transform: perspective(2000px) rotateX(0deg);  }
}
@-o-keyframes flipA1B_A2B {         0%  {  -o-transform: perspective(2000px) rotateX(90deg);  }
                                    100%{  -o-transform: perspective(2000px) rotateX(0deg);  }
}
@keyframes flipA1B_A2B {            0%  {  transform: perspective(2000px) rotateX(90deg);  }
                                    100%{  transform: perspective(2000px) rotateX(0deg);  }
}

/** Spinner / Looper - Loading indicator */
@-webkit-keyframes spinLoader {     0%   {  -webkit-transform: rotate(0deg);  }
                                    100% {  -webkit-transform: rotate(360deg);  }
}
@-moz-keyframes spinLoader {        0%   {  -moz-transform: rotate(0deg);  }
                                    100% {  -moz-transform: rotate(360deg);  }
}
@-o-keyframes spinLoader {          0%   {  -o-transform: rotate(0deg);  }
                                    100% {  -o-transform: rotate(360deg);  }
}
@keyframes spinLoader {             0%   {  transform: rotate(0deg);  }
                                    100% {  transform: rotate(360deg);  }
}
.faded{
    opacity: 0.2;
}
.off{
    -webkit-animation-play-state:paused;
    -moz-animation-play-state:paused;
    -o-animation-play-state:paused;
    animation-play-state:paused;
}
